import { Injectable } from '@angular/core';
import {PostsService} from '../../services/postsSrevice';
import {HttpClient} from '@angular/common/http';
import {fillFormData, trimIfString} from '../../helper';
import {AuthService} from '../../services/auth.service';
import {Observable} from "rxjs";
import {UpdatePostRequest} from "../dto/post";

@Injectable({
  providedIn: 'root'
})
export class AdminPostsService extends PostsService {

  constructor(protected authService:AuthService,http: HttpClient) {
    super(authService,http);
  }

  store(data:object){
    // for(let value of Object.keys(data)){
    //   let v = data[value];
    //   if (value !== "content" && typeof v === 'string') {
    //     data[value] = data[value].trim();
    //   }
    // }
    console.log(data);
    return this.http.post('api/post',fillFormData(data,false));
  }

  update(data:UpdatePostRequest){
    console.log(data);

    return this.http.put('api/post/',trimIfString(data));
  }

  updateHomePage(data:object){
    console.log(data);
    return this.http.put('home',trimIfString(data));
  }

  delete(postId:number): Observable<any>{
    return this.http.delete('api/post/'+postId);
  }




}
