import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {fillFormData, toast, toastError} from '../helper';
import {Router} from '@angular/router';


interface data {
  success:boolean,
  accessList: string[]
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  BASE_URL = '';
  constructor(private http: HttpClient, private router: Router ) {}
  login(username: string, password: string){

    let subscription = this.http.post<{token:string}>('auth/login',fillFormData({username,password}));
    subscription.subscribe(response => {
      if (response.token){
        toast('تم تسجيل الدخول');
        localStorage.setItem('access_token',response.token);
        localStorage.setItem('name',username);
        this.router.navigate(['admin']);
      }else {
        toast(response);
      }    }
      ,error => {
        console.debug("error while login")
        console.debug(error)
      });

    return subscription;
  }

  logout(){
    toast('تم تسجيل الخروج');
    localStorage.removeItem('access_token');
    this.router.navigate(['home']);
  }

  get isLoggedIn(){
    return (localStorage.getItem('access_token') != null);
  }

  get CanAccces(){
    return this.isLoggedIn;
  }

  // setLogin(newStatus: boolean){
  //   localStorage.setItem('isLoggedIn',String(newStatus));
  // }
  //
  // getAccessList(){
  //   this.isLoggedIn
  // }
  //
  // setAccessList(accessList: string[]){
  //   localStorage.setItem('AccessList',JSON.stringify(accessList));
  // }


}
