import Quill from 'quill';
import {customLink} from 'src/app/editor/customLink';
import BlotFormatter from 'quill-blot-formatter';

export class EditEditor {
  private editor:Quill;

  constructor() {
    this.initEditor();
  }

  private initEditor(){
    var Font = Quill.import('formats/font');
    // We do not add Aref Ruqaa since it is the default
    Font.whitelist = ['Amiri','Tajawal', 'serif','monospace'];
    Quill.register(Font, true);
    Quill.register('modules/blotFormatter', BlotFormatter);
    var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike','link', 'image'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color':  ["#b69e40","#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': ['','Amiri','Tajawal', 'serif','monospace'] }],
      [{ 'align': [] }],

      ['clean'],
      ['video']
      // remove formatting button

    ];

    this.editor = new Quill('#editor', {
      modules: {
        toolbar: toolbarOptions,
        blotFormatter: {}
      },
      theme: 'snow'
    });
    this.editor.format('align', 'right');
    this.editor.format('direction', 'rtl');
    var toolbar = this.editor.getModule('toolbar');
    // toolbar.addHandler('image', imageHandler.bind(this.editor));
    this.addAutoEmbed();
  }



  private addAutoEmbed(){
    var Delta = Quill.import('delta');
    this.editor.clipboard.addMatcher(Node.TEXT_NODE, function(node, delta) {
      var regex = /https?:\/\/[^\s]+/g;
      if(typeof(node.data) !== 'string') return;
      var matches = node.data.match(regex);

      if(matches && matches.length > 0) {
        var ops = [];
        var str = node.data;

        matches.forEach(function(match) {
          var split = str.split(match);
          if(match.match(/\.(png|jpg|jpeg|gif)$/)!=null){
            var beforeLink = split.shift();
            ops.push({ insert: beforeLink });
            ops.push({ insert:{image: match}/*, attributes: { link: match }*/
            });

            str = split.join(match);
          } else if (0){//youtube

          }else { //if link is not an image
            var beforeLink = split.shift();
            ops.push({ insert: beforeLink });
            ops.push({ insert: match, attributes: { link: match }
            });
            str = split.join(match);
          }
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }
      return delta;
    });
  }

  setContents(content:any){
    this.editor.setContents(JSON.parse(content));
  }

  getContents(){
    return this.editor.getContents();
  }
}
