import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {removeLoading, toast} from '../helper';
import {Route, Router} from '@angular/router';
import {environment} from "../../environments/environment";

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiReq = req.clone({ url: `${environment.baseUrl}${req.url}` });
    return next.handle(apiReq).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem('access_token');
          window.location.reload();
        }else if (error.status === 404) {
          console.log("404");
          let base = window.location.origin;
          // window.location.href = base+"/404";
        }
        removeLoading();
        return throwError(error);
      })
    );
  }
}

