import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {Category} from "./models/category";

export function  toggleClasses(el, class1: string , class2: string ) {
  if (typeof  el === 'string' ) { el = $(el);}

  if (el.classList.contains(class1)){
    el.classList.remove(class1);
    el.classList.add(class2);
  }else {
    el.classList.remove(class2);
    el.classList.add(class1);
  }

}


export function  toggleClass(el, className: string) {
  if (typeof  el === 'string' ) { el = $(el);}

  if (el.classList.contains(className)){
    el.classList.remove(className);
  }else {
    el.classList.add(className);
  }

}
export function $(selector) {
  return document.querySelector(selector);
}



export function hide(el) {
  if (typeof  el === 'string') { el = $(el); }
  el.classList.add('hide');
}
export function display(el) {
  if (typeof  el === 'string') { el = $(el); }
}

export function clog(msg) {
  console.log(msg);
}


export function slideUp(target, duration=500) {
  if (typeof  target === 'string' ) { target = $(target);}
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout( () => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    //alert("!");
  }, duration);
}

export function slideDown(target, duration=500) {
  if (typeof  target === 'string' ) { target = $(target);}
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none')
    display = 'block';

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout( () => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

export function slideToggle (target, duration = 500) {
  if (typeof  target === 'string' ) { target = $(target);}
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
}


export function toastError(msg ,timeout = 2.95) {
  toast(msg," error-toast",timeout);
}

export function toast(msg ,className ='',timeout = 2.95) {
  let toastBody =  document.createElement('div');
  toastBody.innerText = msg;
  toastBody.className = "toast "+className;
  document.body.insertAdjacentElement("beforeend",toastBody);
   let animTime = (timeout- 0.45);
  toastBody.style.animation = `fadein 0.5s, fadeout 0.5s ${animTime}s`;
  toastBody.style.webkitAnimation = `fadein 0.5s, fadeout 0.5s ${animTime}s`;
  toastBody.className += " show";
   setTimeout(function(){
    toastBody.classList.remove("show");
     setTimeout(function(){/*toastBody.remove()*/},10)
   }, timeout*1000);

}

export function fillFormData(data,trim = true) {
  if (trim) data = trimIfString(data);
  let formData = new FormData();
  for(let value of Object.keys(data)){
    formData.append(value,data[value]);
  }
  return data;
}
export function trimIfString(data) {
  if (data === null || typeof data === "undefined") return false;
  else if (Array.isArray(data)) {
    return trimArrayStrings(data);
  }else if (typeof data === 'object'){
    return trimObjStrings(data)
  } else if (typeof data === 'string'){
    return data.trim();
  } else {
    return false;
  }
}

function trimObjStrings(data,exclude:[] = []) {
  if (data === null || typeof data === "undefined") return  [];
  for(let value of Object.keys(data)){
    let v = data[value];
    if (typeof v === 'string'){
      data[value] = v.trim();
    }else if (Array.isArray(v)){
      data[value] = v = trimArrayStrings(v);
    }else if (typeof v === 'object'){
      data[value] = v = trimObjStrings(v);
    }
  }
  return data;
}
function trimArrayStrings(data) {
  data.forEach((value, i)=>{
    if (value === null || typeof value === "undefined") return;
    if (typeof value === 'string'){
      data[i] = value.trim();
    }else if (Array.isArray(value)){
      data[i] = trimArrayStrings(value);
    }else if (typeof value === 'object'){
      data[i] = trimObjStrings(value);
    }
  });
  return data;
}


export var DateDiff = {
  inMinuets:function (d1,d2){
    return ((d1 - d2)/1000)/60;
  },
  inHours:function (d1,d2){
    return this.inMinuets(d1,d2)/60;
  },
  inDays: function(d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt(''+((t2-t1)/(24*3600*1000)));
  },

  inWeeks: function(d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt(''+((t2-t1)/(24*3600*1000*7)));
  },

  inMonths: function(d1, d2) {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return (d2M+12*d2Y)-(d1M+12*d1Y);
  },

  inYears: function(d1, d2) {
    return d2.getFullYear()-d1.getFullYear();
  }
}



export function showLoading(block:boolean = false) {
  let classes = '';
  if (block){classes = 'block';window.scrollTo(0, 0);}
  document.body.style.overflow = "hidden";
  document.body.insertAdjacentHTML("beforeend",
    `<div id='loader' class="loader-container ${classes}">
<div  class="loader ">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    </div>
`);
}


export function removeLoading() {
    let load  = $('#loader');
    if (load !== null && typeof load !== "undefined"){
      load.remove();
      document.body.style.overflow = "";
    }
}

@Injectable()
export  class GlobalVariables {
  // links:any = [{name: "أخبار الحب", id: 1}, {name: "لقاءات الأسرة", id: 2}, {name: "شجرة الأسرة", id: 3}];

  public links = new BehaviorSubject<Category[]>([{name: "أخبار الأسرة", id: 1}, {name: "لقاءات الأسرة", id: 2}, {name: "شجرة الأسرة", id: 3}]);
  // Observable navItem stream
  links$ = this.links.asObservable();
  // service command
  updateLinks(newValue:any) {
    this.links.next(newValue);
  }
}

