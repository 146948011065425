import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PostEditComponent} from '../post/post-edit/post-edit.component';
import {EditEditor} from '../../../editor/edit-editor';
import {clog, removeLoading, showLoading, toast} from '../../../helper';
import {AdminPostsService} from '../../services/admin-posts.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-edit',
  templateUrl: './home-edit.component.html',
  styleUrls: ['./home-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class HomeEditComponent implements OnInit {
  protected editor:EditEditor;

  constructor(private router: Router,private adminPostsService:AdminPostsService) { }

  ngOnInit() {
    showLoading(true);
    this.editor = new EditEditor();
    this.adminPostsService.getHomeContent().subscribe(HomeContent => {
      this.editor.setContents(HomeContent['content']);
      removeLoading();
    });
  }

  submit(){
    showLoading(true);
    clog('showLoading done');
    let home = {"content":JSON.stringify(this.editor.getContents())};
    this.adminPostsService.updateHomePage(home).subscribe(response => {
      toast("تم تعيل الصفحة الرئيسية بنجاح");
      this.router.navigate(['admin']).then(value => {
        removeLoading();
      });
    },error => {clog(error)}) ;
    clog(home);

  }

}
