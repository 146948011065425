import Quill from 'quill';
import {customLink} from 'src/app/editor/customLink';

export class ShowEditor {
  private editor:Quill;
  private initEditor(){
    var Font = Quill.import('formats/font');
    // We do not add Aref Ruqaa since it is the default
    Font.whitelist = ['Amiri','Tajawal', 'serif','monospace'];
    Quill.register(Font, true);
    Quill.register(customLink);
    this.editor  = new Quill("#editor");
    this.editor.enable(false);
    this.editor.setContents()
  }

  constructor() {
    this.initEditor();
  }

  setContents(content:any){
    this.editor.setContents(JSON.parse(content));
  }

}
