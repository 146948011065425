import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-posts-lists',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Input() id;
  @Input() message;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onPositive:Function;
  onNegative:Function;

}
