import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {clog, removeLoading, showLoading, toast, toastError} from '../../../../helper';
import { Router } from '@angular/router';
import {AdminPostsService} from '../../../services/admin-posts.service';
import {CategoryService} from '../../../../services/category.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerConfig, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {IslamicI18n} from '../../../../services/islamic-i18n.service';
import {EditEditor} from '../../../../editor/edit-editor';

@Component({
  selector: 'app-add',
  templateUrl: './post-add.component.html',
  styleUrls: ['./post-add.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    {provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura},
    {provide: NgbDatepickerI18n, useClass: IslamicI18n},NgbDatepickerConfig
  ]
})




export class PostAddComponent implements OnInit {
  protected editor:EditEditor;
  cats = null;
  pageTitle = "منشور جديد";
  model: NgbDateStruct = null;
  form: FormGroup ;
  showFromErrors = false;
  dateError = false;

  constructor(protected postsService: AdminPostsService,protected catsService: CategoryService,
              protected router: Router,protected fb:FormBuilder
              ,protected calendar: NgbCalendar,protected config: NgbDatepickerConfig)
  {}



  ngOnInit() {
    this.editor = new EditEditor();
    this.getCategories();
    this.setDatePickerConfiguration();
    this.initForm();
  }

  initForm(){
      this.form = this.fb.group({
        title:['',Validators.required],
        coverImage:[null],
        postDate:[''],
        categoryId:['',Validators.required],
        enableComments:[true,Validators.required],
      });
  }

  getCategories(){
    this.catsService.getCategories().subscribe(response =>{
      this.cats = response;
      clog(response);
    });
  }



  submit(){
    if (this.isOtherThanDateInvalid()) {this.showFromErrors = true; toastError('بينات غير مكتملة');return;}
    showLoading(true);
    let post  = this.form.value;
    if (this.model !== null )post.postDate = this.model.year+"-"+this.model.month+"-"+this.model.day;
    else {delete post.postDate;}
    post['content'] = JSON.stringify(this.editor.getContents());
    this.submitToApi(post);
  }

  protected submitToApi(post){
    showLoading();
    this.postsService.store(post).subscribe(response => {
      removeLoading();
      toast("تم الحفظ بنجاح");
      this.router.navigate(['admin']);
      removeLoading();
    },error => {clog(error)}) ;
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  setDatePickerConfiguration(){
    this.config.minDate = {year: 1200, month: 1, day: 1};
    // this.config.maxDate = {year: 1, month: 12, day: 31};

  }

  protected isOtherThanDateInvalid(){
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid && name !== "postDate") {
        return true;
      }
    }

    return false;
  }

}
// imageHandler() {
//   var range = this.getSelection();
//   var value = prompt('What is the image URL');
//   if(value){
//     this.insertEmbed(range.index, 'image', value, Quill.sources.USER);
//   }
// }


