import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {clog} from '../helper';
import {Category} from "../models/category";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private BASE_URL = "";
  constructor(protected http:HttpClient) { }

  // getCategories() { return [{id:"2",name:"مناسبات"},{id:"1",name:"أعياد"}]; }
  getCategories(fromApi = false): ReplaySubject<Category[]> {
    let res:ReplaySubject<Category[]> = new ReplaySubject(1);
    if (!fromApi){
      let cat = localStorage.getItem("cats");
      clog(cat);
      res.next(JSON.parse(cat));
    } else {
        this.http.get<Category[]>('guest/all-categories').subscribe(response => {
        localStorage.setItem('cats',JSON.stringify(response));
        res.next(response);
      });
    }

    return res;
  }


}
