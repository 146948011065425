import {HttpParams} from "@angular/common/http";


export class ParambleDto {
  asParam(): HttpParams {
    return new HttpParams({ fromObject: this as any });
  }
}

export class PageableSort {
  property:string = '';
  direction: QueryParamDirection = QueryParamDirection.ASC;
}


export enum QueryParamDirection {
  ASC = 'ASC',
  DESC = "DESC"
}


export class PageableQueryParam extends ParambleDto {

  public static of(page:number,size:number = 10,sort?: PageableSort[]): HttpParams {
    let pageable = new PageableQueryParam();
    pageable.page = page;
    pageable.size = size;
    pageable.sort = sort;
    return pageable.asParam();
  }
   asParam(): HttpParams {
    let params =
      new HttpParams()
        .append('page',String(this.page))
        .append('size',String(this.size));

    if (this.sort !== undefined) this.sort.forEach(value => {
      let dir = (value.direction === undefined? QueryParamDirection.ASC:value.direction);
      params = params.append('sort',`${value.property},${dir}`)
    })
    return params;
  }

  page: number = 1;
  size: number = 10;
  sort?: PageableSort[] = [];
}
