import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {fillFormData} from '../helper';
import {Observable} from "rxjs";
import {Post} from "../models/post";

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(protected http: HttpClient){}
  getCommentsByPostId(postId){
    return this.http.get<[]>('comment/post/'+postId);
  }

  store(data):Observable<Post>{
    return this.http.post<Post>('comment/commenter',fillFormData(data));
  }

  delete(comment_id){
    return this.http.delete('comment/'+comment_id);
  }


  //give the ability to the commenter to destroy his comment , after less than 7 minuets of posting it
  deleteMyComment(comment_id){
    return this.http.delete('comment/commenter/'+comment_id);
  }
}
