import { Component, OnInit } from '@angular/core';
import {clog, slideToggle,GlobalVariables, slideUp} from '../../helper';
import { CategoryService } from '../../services/category.service';
import {AuthService} from '../../services/auth.service';
import {Category} from "../../models/category";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  links:Category[];
  constructor(private globalVariables :GlobalVariables, private pageService: CategoryService, public Auth:AuthService) {}
  ngOnInit() {
    this.links = this.globalVariables.links.getValue();
    this.pageService.getCategories(true).subscribe(response => {
      this.links  = response;
      this.globalVariables.updateLinks(this.links);
    });

  }

  logout(){
    this.Auth.logout();
  }
  public menuClicked() {
    slideToggle('.responsive_menu');
  }

  public menuDown(isResponsive:boolean){
    if (isResponsive)this.menuClicked();
  }

  public slideUpResponsive(){

    slideUp('.responsive_menu');
  }

}



