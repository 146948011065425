import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostListComponent } from './ui/post/list/postsListComponent';
import { PostShowComponent } from './ui/post/show/post-show.component';
import {HomeComponent} from './ui/home/home.component';
import {LoginComponent} from './ui/login/login.component';
import {AdminPostListsComponent} from './admin/ui/post/admin-posts-lists/admin-post-lists.component';
import {PostAddComponent} from './admin/ui/post/add/post-add.component';
import {PostEditComponent} from './admin/ui/post/post-edit/post-edit.component';
import {AuthGuard} from './auth.guard';
import {PageNotFoundComponent} from './ui/page-not-found/page-not-found.component';
import {ContactUsComponent} from './ui/contact-us/contact-us.component';
import {HomeEditComponent} from './admin/ui/home-edit/home-edit.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent},
  { path: 'posts', component: PostListComponent},
  { path: 'posts/3', redirectTo:"post/2"},
  { path: 'posts/:cat', component: PostListComponent},
  { path: 'post/:id', component: PostShowComponent},
  { path: 'contact_us', component: ContactUsComponent},

  { path: 'admin/home/edit', component: HomeEditComponent,canActivate:[AuthGuard]},
  { path: 'admin', redirectTo: "admin/posts" , pathMatch: 'full'},
  { path: 'admin/posts', component: AdminPostListsComponent ,canActivate:[AuthGuard]},
  { path: 'admin/posts/:cat', component: AdminPostListsComponent ,canActivate:[AuthGuard]},
  { path: 'admin/post/add', component: PostAddComponent,canActivate:[AuthGuard]},

  { path: 'admin/post/edit/:id', component: PostEditComponent,canActivate:[AuthGuard]},
  { path: '**', redirectTo:"404" },
  { path: '404', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
