import { Injectable } from '@angular/core';
import {CategoryService} from '../../services/category.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {fillFormData, trimIfString} from '../../helper';
import {Observable} from "rxjs";
import {Category} from "../../models/category";

@Injectable({
  providedIn: 'root'
})
export class AdminCategoryService extends CategoryService {


  constructor(http: HttpClient) {
    super(http);
  }

  store(formData) : Observable<Category> {
    return this.http.post<Category>("api/category",fillFormData(formData));
  }

  update(id,formData): Observable<Category>{
    trimIfString(formData);
    formData.categoryId = id;
    return this.http.put<Category>("api/category", formData);
  }

  delete(id):Observable<any>{
    return this.http.delete("api/category/"+id);
  }
}
