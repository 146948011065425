import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {clog, removeLoading, showLoading, toast, toastError} from '../../helper';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private http: HttpClient, private route:Router) { }

  contactUsForm:FormGroup = new FormGroup({
    name: new FormControl('',Validators.required),
    contact: new FormControl('',Validators.required),
    // subject: new FormControl('',Validators.required),
    message: new FormControl('',Validators.required),
  });

  ngOnInit() {
  }
  formSubmitted ="";

  submit(){
    this.formSubmitted = 'formSubmitted';
    if (this.contactUsForm.invalid){
      toastError('أكمل الفراغات المطلوبة');
      return;
    }else {
      let data = this.contactUsForm.value;
      data.subject = data.name;
      showLoading();
      clog(data);
      this.http.post('contact_us/form',data).subscribe(res =>{
        removeLoading();
        toast("شكرا لك تم ارسال طلبك");
        this.route.navigate(['home']);
      });
      // this.contactUsForm.store(data).subscribe(res => {
      //   }
      //   ,error =>{clog(error)} )
    }
  }
}
