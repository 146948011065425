import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {clog, GlobalVariables, removeLoading, showLoading} from '../../helper';
import {Subscription} from 'rxjs';
import {ShowEditor} from '../../editor/show-editor';
import {PostsService} from '../../services/postsSrevice';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,


})
export class HomeComponent implements OnInit,OnDestroy {
  links:any;
  subscription:Subscription;
  editor:ShowEditor;

  constructor(private postsService: PostsService,private globalVariables :GlobalVariables //,private sanitization:DomSanitizer
  ) {

  }

  ngOnInit() {
    showLoading(true);
    this.editor = new ShowEditor();
    this.postsService.getHomeContent().subscribe(response => {
      this.editor.setContents(response['content']);
      removeLoading ();
    });


    this.subscription = this.globalVariables.links$.subscribe(
      item => this.links = item
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
