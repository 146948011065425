import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PostsService} from '../../../services/postsSrevice';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {$, clog, DateDiff, removeLoading, showLoading, toast, toastError} from '../../../helper';
import Quill from 'quill';
import {Post} from '../../../models/post';
import {Comment} from '../../../models/comment';
import {CommentService} from '../../../services/comment.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {SEOService} from "../../../services/SEO.service";
import {ShowEditor} from '../../../editor/show-editor';

@Component({
  selector: 'app-post-viwe-component',
  templateUrl: './post-show.component.html',
  styleUrls: ['./post-show.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostShowComponent implements OnInit{

  editor:ShowEditor;
  post :Post;
  coverImage = null;
  postDate;
  title;
  enableComments = false;
  protected id = 0;
  comments = [];
  commentForm:FormGroup = new FormGroup({
    commenter: new FormControl('',Validators.required),
    content: new FormControl('',Validators.required)
  });
  constructor(protected postsService: PostsService,protected commentService: CommentService,public authService :AuthService ,protected SEOService: SEOService
              ,protected route: ActivatedRoute,private sanitization:DomSanitizer) {}

  ngOnInit() {
    showLoading(true);
    this.editor = new ShowEditor();
    this.route.params.subscribe(params => {
      this.id = params.id;
      let canRemove = this.doesHeHaveComment();
      clog(canRemove);
      this.postsService.getPostById(params.id).subscribe(res =>{
        this.coverImage = res.coverImage;
        this.title = res.title;
        this.postDate = res.postDate;
        this.enableComments = res.enableComments;
        this.editor.setContents(res.content);
        this.SEOService.setPostTitleAndMeta(this.title);
        removeLoading ();
      });
      this.commentService.getCommentsByPostId(params.id).subscribe(response =>{
        if (canRemove[0]){
          let res:any = response.find(({ id }) => id === canRemove[1]);
          if (typeof res !== 'undefined' && res !== null){
            res.can = true;
            this.commented = true;
          }else {
            clog('notFind');
            localStorage.removeItem('hasComment');
          }
        }
        this.comments = response;
        clog(this.comments);
      });
    });

  }


  formSubmitted = '';
  commented = false;

  newComment() {
    this.formSubmitted = 'formSubmitted';
    if (this.commentForm.invalid){
      toastError('أكمل الفراغات المطلوبة');
      return;
    }else {
      let data = this.commentForm.value;
      data.post_id = this.id;
      this.commentService.store(data).subscribe(res => {
        localStorage.setItem('hasComment',JSON.stringify(res));
        data.created_at = res.postDate;
        data.can = true;
        this.appendComment(data);
      }
      ,error =>{clog(error)} )
    }
  }

  appendComment(data) {
    this.commented = true;
    this.comments.push(data);
  }



  removeComment(){
    let result = this.doesHeHaveComment();
    if (result[0]) {
      showLoading(true);
        this.commentService.deleteMyComment(result[1]).subscribe(res => {
          localStorage.removeItem('hasComment');
          toast('تم الحذف بنجاح');
          window.location.reload();
        })
    }

  }


  doesHeHaveComment(){
    let hasComment = localStorage.getItem('hasComment');
    if (hasComment === null || typeof hasComment === 'undefined'){
      return [false,1];}
    hasComment = JSON.parse(hasComment);
    if (this.id != hasComment['post_id']){
      console.log('id');
      return [false,2];
    }
    let commentDate = new Date();
    let full = hasComment['date'].split(' ');
    let d = full[0];
    let t = full[1];
    d = d.split("-");
    t = t.split(":");
    commentDate.setFullYear(parseInt(d[0]),parseInt(d[1])-1,parseInt(d[2]));
    commentDate.setHours(parseInt(t[0]),parseInt(t[1]),parseInt(t[2]));
    clog(commentDate);
    if (DateDiff.inMinuets(new Date(), commentDate) < 7){
      return [true,hasComment['id']]
    }else {
      localStorage.removeItem('hasComment');
    }
    return [false,3];
  }


  adminRemoveComment(commentId){
    if (this.authService.isLoggedIn){
      showLoading(true);
      this.commentService.delete(commentId).subscribe(res => {
        localStorage.removeItem('hasComment');
        toast('تم الحذف بنجاح');
        window.location.reload();
      })
    }
  }
}
