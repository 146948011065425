import { Component, OnInit } from '@angular/core';
import { PostsService } from '../../../services/postsSrevice';
import {ActivatedRoute, Router} from '@angular/router';
import {clog, removeLoading, showLoading} from '../../../helper';
import {DomSanitizer} from '@angular/platform-browser';
import {Post} from "../../../models/post";
@Component({
  selector: 'app-list',
  templateUrl: './postsListComponent.html',
  styleUrls: ['./postsListComponent.scss'],
  providers: [PostsService]
})
export class PostListComponent implements OnInit {
  posts: Post[];
  admin = false;
  cats = null;
  constructor(protected postsService: PostsService,protected ActivatedRoute: ActivatedRoute,private sanitization:DomSanitizer,public route:Router) {}

  ngOnInit() {
    this.ActivatedRoute.params.subscribe(params => {
      clog(params);
      showLoading();
      this.postsService.getPostsByCat(params['cat']).subscribe(response =>{
        this.posts = response;
        removeLoading();
      });

    });

  }

  getBackground(image) {
    return this.sanitization.bypassSecurityTrustStyle(`url(${image})`);
  }

  newCat(){}
  editCat(id){}
  deleteCat(id){}
  deletePost(id){}
}
