import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PostAddComponent} from '../add/post-add.component';
import {$, clog, removeLoading, showLoading, toast} from '../../../../helper';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminPostsService} from '../../../services/admin-posts.service';
import {CategoryService} from '../../../../services/category.service';
import {NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerConfig, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {IslamicI18n} from '../../../../services/islamic-i18n.service';
import {FormBuilder} from '@angular/forms';
import {Post} from "../../../../models/post";
import {UpdatePostRequest} from "../../../dto/post";

@Component({
  selector: 'app-post-edit',
  templateUrl: '../add/post-add.component.html',
  styleUrls: ['../add/post-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura},
    {provide: NgbDatepickerI18n, useClass: IslamicI18n},NgbDatepickerConfig
  ]
})
export class PostEditComponent extends PostAddComponent implements OnInit {
  pageTitle = "تعديل منشور";
  postIdForEdit = 0;
  coverImage = "";
  postDate;
  title;
  constructor(private activatedRoute :ActivatedRoute
              ,postsService: AdminPostsService,  catsService: CategoryService
              ,router: Router,protected fb:FormBuilder
              , calendar: NgbCalendar,config: NgbDatepickerConfig) {
    super(postsService,catsService,router,fb, calendar,config);
  }

  ngOnInit() {
    super.ngOnInit();
    this.activatedRoute.params.subscribe(params => {
      showLoading(true);
      this.postsService.getPostById(params.id).subscribe(res =>{
        this.loadData(res)
      });
      this.postIdForEdit = params.id;
    })
  }

  private loadData(res:Post){
    this.form.setValue({
      title:res.title,
      coverImage:res.coverImage== '' || res.coverImage == null? "":res.coverImage,
      categoryId:res.category.id,
      postDate:res.postDate == undefined ? null : res.postDate,
      enableComments:res.enableComments,
    });

    this.form.get('postDate').setValue(res.postDate);
    if (res.postDate !== null && res.postDate !== '' ){
      let date = res.postDate.split('-');
      this.model = {year:parseInt(date[0]),month:parseInt(date[1]),day:parseInt(date[2])};
    }
    // res.content = JSON.parse(res.content);
    this.editor.setContents(res['content']);
    removeLoading();
  }

  protected submitToApi(post:UpdatePostRequest) {
    post.postId = this.postIdForEdit;
    this.postsService.update(post).subscribe(response => {
      toast("تم التعديل بنجاح");
      this.router.navigate(['admin']);
      removeLoading();
    },error => {clog(error)}) ;
  }

}
