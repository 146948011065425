import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PostListComponent } from './ui/post/list/postsListComponent';
import { PostShowComponent } from './ui/post/show/post-show.component';
import { PostsService } from './services/postsSrevice';
import { HeaderComponent } from './ui/header/header.component';
import { HomeComponent } from './ui/home/home.component';
import { LoginComponent } from './ui/login/login.component';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './auth.guard';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { AdminPostListsComponent } from './admin/ui/post/admin-posts-lists/admin-post-lists.component';
import { PostAddComponent } from './admin/ui/post/add/post-add.component';
import { PostEditComponent } from './admin/ui/post/post-edit/post-edit.component';
import { JwtModule } from '@auth0/angular-jwt';
import { PageNotFoundComponent } from './ui/page-not-found/page-not-found.component';
import { DeleteModalComponent } from './admin/ui/post/post-delete/delete-modal.component';
import {NgbDatepickerModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { CategoryActionComponent } from './admin/ui/Category/category-action/category-action.component';
import {APIInterceptor} from './services/apiinterceptor.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ContactUsComponent } from './ui/contact-us/contact-us.component';
import {GlobalVariables} from './helper';
import { HomeEditComponent } from './admin/ui/home-edit/home-edit.component';



export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
  entryComponents: [DeleteModalComponent,CategoryActionComponent],
  // exports: [ DeleteModalComponent ],
  declarations: [
    AppComponent,
    PostListComponent,
    PostShowComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    AdminPostListsComponent,
    PostAddComponent,
    PostEditComponent,
    PageNotFoundComponent,
    DeleteModalComponent,
    CategoryActionComponent,
    ContactUsComponent,
    HomeEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter:tokenGetter,
        whitelistedDomains: ['http://api.4alyahya.com/api'],
        blacklistedRoutes: [
          'http://api.4alyahya.com/api/category/',
          'http://api.4alyahya.com/api/post/',
          'http://api.4alyahya.com/api/post/cat/',
          'http://api.4alyahya.com/api/admin/login',
          'http://api.4alyahya.com/api/comment/post/',
          'http://api.4alyahya.com/api/comment/commenter/',
          'http://api.4alyahya.com/api/contact_us/form/',
        ],
        // headerName:"token",
        skipWhenExpired: false
      }
    }),
    NgbModalModule,
    NgbDatepickerModule
  ],

  providers: [HttpClient,PostsService,AuthService,AuthGuard,GlobalVariables
    ,{
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
// BASE_URL:string = "api.4alyahya.com/";

