import { Component, OnInit } from '@angular/core';
import {$, clog, removeLoading, showLoading, toast, toastError} from '../../helper';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit() {
  }

  login(){
    let username = $('#username').value.trim();
    let password = $('#password').value.trim();
    if (username === '' || password === '' ){
        toastError('بيانات ناقصة');
    }else {
        showLoading();
        this.auth.login(username,password).subscribe(
          response => {
          removeLoading();
        }
        ,error => {
            if (error.status === 403){
              toastError('بينات دخول خاطئة');
            }else if (error.status === 500){
              toastError('حدث خطأ ما راجع الدعم الفني');
            }else {
              toastError('بينات دخول خاطئة');
            }
            $('#username').value = "";
            $('#password').value = "";
          })
    }
  }

}
