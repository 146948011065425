import { Component, OnInit } from '@angular/core';
import {PostListComponent} from '../../../../ui/post/list/postsListComponent';
import {AdminPostsService} from '../../../services/admin-posts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteModalComponent} from '../post-delete/delete-modal.component';
import {$, clog, toast} from '../../../../helper';
import {CategoryActionComponent} from '../../Category/category-action/category-action.component';
import {AdminCategoryService} from '../../../services/admin-category.service';



@Component({
  selector: 'app-admin-posts-lists',
  templateUrl: '../../../../ui/post/list/postsListComponent.html',
  styleUrls: ['../../../../ui/post/list/postsListComponent.scss']
})
export class AdminPostListsComponent extends PostListComponent implements OnInit {
  admin = true;
  cats = null;
  constructor(postsService: AdminPostsService,ActivatedRoute: ActivatedRoute,sanitization:DomSanitizer,public route:Router
              ,private modalService: NgbModal,private PostService:AdminPostsService,private  catsService:AdminCategoryService) {
    super(postsService,ActivatedRoute, sanitization,route,);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getCategories();
  }
  getCategories(){
    this.catsService.getCategories().subscribe(response =>{
      this.cats = response;
      clog(response);
    });
  }

  deletePost(postId:number){
      const modalRef = this.modalService.open(DeleteModalComponent);
      modalRef.componentInstance.id = postId;
      modalRef.componentInstance.message = "هل أنت متأكد من حذف هذا المنشور";
      modalRef.componentInstance.onNegative = () => {modalRef.close('Close click')};
      modalRef.componentInstance.onPositive = () => {
        this.PostService.delete(postId).subscribe(response => {
          modalRef.close('Close click');
          toast('تم الحذف بنجاح');
          $('#c'+postId).remove();
        }
      )}
  }


  newCat(){
    const modalRef = this.modalService.open(CategoryActionComponent);
    modalRef.componentInstance.title = "تصنيف جديد";
    modalRef.componentInstance.submitBtnText = "إنشاء";
    modalRef.componentInstance.onOk = (name) => {
      this.catsService.store(name).subscribe(response => {
        toast('تم إنشاء التصنيف بنجاح');
        modalRef.close();
        window.location.reload();
      });
    };
  }

  editCat(cat_id){
    if (!parseInt(cat_id))return;
    let text = $(`option[value='${cat_id}']`).innerText;
    const modalRef = this.modalService.open(CategoryActionComponent);
    modalRef.componentInstance.title = "تعديل تصنيف";
    modalRef.componentInstance.submitBtnText = "حفظ";
    modalRef.componentInstance.inputValue = text;
    modalRef.componentInstance.onOk = (name) => {
      this.catsService.update(cat_id,name).subscribe(response => {
        toast('تم تعديل التصنيف بنجاح');
        modalRef.close();
        window.location.reload();
      });
    };
  }

  deleteCat(cat_id){
    if (!parseInt(cat_id))return;
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.id = cat_id;
    modalRef.componentInstance.message = "هل أنت متأكد من حذف هذا التصنيف";
    modalRef.componentInstance.onNegative = () => {modalRef.close('Close click')};
    modalRef.componentInstance.onPositive = () => {
      this.catsService.delete(cat_id).subscribe(response => {
          toast('تم الحذف بنجاح');
        modalRef.close('Close click');
        window.location.reload();
        }
      )}
  }

}
