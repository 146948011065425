import Quill from 'quill';
import {log} from 'util';

const Link = Quill.import('formats/link');
export class customLink extends Link {
  static create (value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (value.startsWith('https://4alyahya.com') || value.startsWith('http://4alyahya.com')) {
      node.setAttribute('target',"_self");
    }
    return node
  }
}
