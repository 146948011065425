import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {toastError} from '../../../../helper';

@Component({
  selector: 'app-category-action',
  templateUrl: './category-action.component.html',
  styleUrls: ['./category-action.component.scss']
})
export class CategoryActionComponent implements OnInit {

  title:string;
  submitBtnText:string;
  onOk:Function;
  inputValue:string;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  showFromErrors = false;
  submit(form: NgForm){
    if (form.invalid) {this.showFromErrors = true; toastError('بينات غير مكتملة');return;}
    this.onOk(form.value);
  }

}
