import { Injectable } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta) { }
  setPostTitleAndMeta(data:string){
    this.title.setTitle(data);
    this.meta.updateTag({ name: 'description', content: data });
  }
}
